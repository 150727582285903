import React from "react";
import "./Footer.css";
import { Facebook, Instagram, LinkedIn, YouTube } from "@mui/icons-material";
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'

// import { faInstagram } from '@fortawesome/free-solid-svg-icons'
function Footer() {
  return (
    <div className="footer">
      <div className="footer_top">
        <div className="footer_top_left">
          <ul>
            <li>
              <Link to="https://bydautoindia.com/privacy-policy">
                Privacy & legal
              </Link>
            </li>
            <li>
              {" "}
              <Link to="https://bydautoindia.com/support">Support</Link>
            </li>
            <li>
              <Link to="https://bydautoindia.com/careers">Careers</Link>{" "}
            </li>
            <li>
              <Link to="https://bydautoindia.com/news">News</Link>{" "}
            </li>
            <li>
              <Link to="https://bydautoindia.com/events">Events</Link>
            </li>
          </ul>
        </div>
        <div className="footer_top_right">
          <ul>
            <li>Follow Us</li>
            <li>
              <Link to="https://www.instagram.com/byd.lucknow/" target="_blank">
                <Instagram />
              </Link>
            </li>
            <li>
              <Link
                to="https://www.facebook.com/SpeedBYD/?_rdr"
                target="_blank"
              >
                {" "}
                <Facebook />
              </Link>
            </li>
            <li>
              <Link
                to="https://www.linkedin.com/company/speedbydlko/?originalSubdomain=in"
                target="_blank"
              >
                {" "}
                <LinkedIn />
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer_bottom">
        <p>All Rights Reserved. © 2022 BYD</p>
        <Link to="https://bydautoindia.com/terms-conditions">
          Terms & Conditions
        </Link>
      </div>
    </div>
  );
}

export default Footer;
