import React, { useState } from "react";
import "./Navbar.css";
import logo from "../../asset/logo.png";
import drive from "../../asset/drive.png";
import location from "../../asset/location.png";
import { Link, useNavigate } from "react-router-dom";
import { MailOutline, Menu, Person, Phone, Search } from "@mui/icons-material";
function Navbar() {
  const histroy = useNavigate();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [models, setModels] = useState(false);
  const [technology, setTechnology] = useState(false);
  const [about, setAbout] = useState(false);
  const [global, setGlobal] = useState(false);
  const [technologyOriented, setTechnologyOriented] = useState(false);
  const [green, setGreen] = useState(false);
  const [corparate, setCorparate] = useState(false);
  return (
    <div className="navbar">
      <div className="navbar_top">
        <Link to="https://bydautoindia.com/">
          <span>
            <Search style={{ color: "#fff" }} className="navbar_top_icon" />
          </span>
        </Link>

        <span>
          <Link to="https://bydautoindia.com/">
            <Phone style={{ color: "#fff" }} className="navbar_top_icon" />
          </Link>
        </span>
        <span>
          <Link to="https://bydautoindia.com/">
            <Person style={{ color: "#fff" }} className="navbar_top_icon" />
          </Link>
        </span>
        <span style={{ border: "transparent" }}>
          <Link to="https://bydautoindia.com/">
            <MailOutline
              style={{ color: "#fff" }}
              className="navbar_top_icon"
            />
          </Link>
        </span>
      </div>
      <div className="navbar_bottom">
        <div className="navbar_bottom_left">
          <img
            src={logo}
            alt="logo"
            onClick={() => histroy("/")}
            style={{
              cursor: "pointer",
              height: "2.7rem",
              objectFit: "contain",
            }}
          />
        </div>
        <div className="navbar_bottom_center">
          <ul>
            <li>
              <span>Models</span>
              <ul className="sub-nav">
                <li>
                  <Link to="https://bydautoindia.com/bydatto3">BYD ATTO 3</Link>{" "}
                </li>
                <li>
                  <Link to="https://bydautoindia.com/all-new-e6">
                    All-New e6
                  </Link>
                </li>
                <li>
                  <Link to="https://bydautoindia.com/bydseal">BYD SEAL</Link>
                </li>
              </ul>
            </li>
            <li>
              <span>Technology</span>
              <ul className="sub-nav">
                <li>
                  <Link to="https://bydautoindia.com/blade-battery">
                    Blade Battery
                  </Link>{" "}
                </li>
                <li>
                  <Link to="https://bydautoindia.com/eplatform3.0">
                    e-Platform 3.0
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <span>About BYD</span>
              <ul className="sub-nav">
                <li>
                  <Link to="https://bydautoindia.com/about_byd?#bydintro">
                    BYD Intro
                  </Link>{" "}
                </li>
                <li className="sub-nav-inner-toggler">
                  <span>Global Corporate</span>
                  <ul className="sub-nav-inner">
                    <li>
                      <Link to="https://bydautoindia.com/about_byd?#globalization">
                        Globalization
                      </Link>
                    </li>
                    <li>
                      <Link to="https://bydautoindia.com/about_byd?#diversification">
                        Diversification
                      </Link>
                    </li>
                    <li>
                      <Link to="https://bydautoindia.com/about_byd?#marketperformance">
                        Market Performance
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="sub-nav-inner-toggler">
                  <span>Technology-Oriented</span>
                  <ul className="sub-nav-inner">
                    <li>
                      <Link to="https://bydautoindia.com/about_byd?#auto">
                        Auto
                      </Link>
                    </li>
                    <li>
                      <Link to="https://bydautoindia.com/about_byd?#eplatform">
                        ePlatform 3.0
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="sub-nav-inner-toggler">
                  <span>Green Future</span>
                  <ul className="sub-nav-inner">
                    <li>
                      <Link to="https://bydautoindia.com/about_byd?#cooltheearth">
                        Cool the Earth by 1°C
                      </Link>
                    </li>
                    <li>
                      <Link to="https://bydautoindia.com/about_byd?#pollutioncontrol">
                        Pollution Control
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="sub-nav-inner-toggler">
                  <span>Corporate Presence</span>
                  <ul className="sub-nav-inner">
                    <li>
                      <Link to="https://bydautoindia.com/about_byd?#csr">
                        CSR
                      </Link>
                    </li>
                    <li>
                      <Link to="https://bydautoindia.com/about_byd?#ar">
                        Corporate Governance
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <Link to="https://bydautoindia.com/news">
                <span>News</span>
              </Link>
            </li>
            <li>
              <Link to="https://bydautoindia.com/events">
                <span>Events</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="navbar_bottom_right">
          <Link to="https://bydautoindia.com/find-dealer">
            <img src={drive} alt="drive" />
            Dealers
          </Link>
          <Link to="/contactus">
            <img src={location} alt="drive" />
            Enquire Now
          </Link>
          <span className="hamburger">
            <Menu
              style={{ color: "#fff" }}
              onClick={() => setHamburgerOpen(!hamburgerOpen)}
            />
          </span>
        </div>
        {hamburgerOpen && (
          <div className="navbar_bottom_mobile">
            <ul>
              <li>
                <span onClick={() => setModels(!models)}>Models</span>
                {models && (
                  <ul className="navbar_bottom_mobile_ul">
                    <li>
                      <Link to="https://bydautoindia.com/bydatto3">
                        BYD ATTO 3
                      </Link>
                    </li>
                    <li>
                      <Link to="https://bydautoindia.com/all-new-e6">
                        All-New e6
                      </Link>
                    </li>
                    <li>
                      <Link to="https://bydautoindia.com/bydseal">
                        BYD SEAL3
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <span onClick={() => setTechnology(!technology)}>
                  Technology
                </span>
                {technology && (
                  <ul className="navbar_bottom_mobile_ul">
                    <li>
                      <Link to="https://bydautoindia.com/blade-battery">
                        Blade Battery
                      </Link>
                    </li>
                    <li>
                      <Link to="https://bydautoindia.com/eplatform3.0">
                        e-Platform 3.0
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <span onClick={() => setAbout(!about)}>About BYD</span>
                {about && (
                  <ul className="navbar_bottom_mobile_ul">
                    <li>
                      <Link to="https://bydautoindia.com/about_byd?#bydintro">
                        {" "}
                        BYD Intro
                      </Link>
                    </li>
                    <li>
                      <span onClick={() => setGlobal(!global)}>
                        Global Corporate
                      </span>
                      {global && (
                        <ul className="navbar_bottom_mobile_ul_ul">
                          <li>
                            <Link to="https://bydautoindia.com/about_byd?#globalization">
                              Globalization
                            </Link>
                          </li>
                          <li>
                            <Link to="https://bydautoindia.com/about_byd?#diversification">
                              Diversification
                            </Link>
                          </li>
                          <li>
                            <Link to="https://bydautoindia.com/about_byd?#marketperformance">
                              {" "}
                              Market Performance
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li>
                      <span
                        onClick={() =>
                          setTechnologyOriented(!technologyOriented)
                        }
                      >
                        Technology-Oriented
                      </span>
                      {technologyOriented && (
                        <ul className="navbar_bottom_mobile_ul_ul">
                          <li>
                            <Link to="https://bydautoindia.com/about_byd?#auto">
                              {" "}
                              Auto
                            </Link>
                          </li>
                          <li>
                            <Link to="https://bydautoindia.com/about_byd?#eplatform">
                              ePlatform 3.0
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li>
                      <span onClick={() => setGreen(!green)}>Green Future</span>
                      {green && (
                        <ul className="navbar_bottom_mobile_ul_ul">
                          <li>
                            <Link to="https://bydautoindia.com/about_byd?#cooltheearth">
                              Cool the Earth by 1°C
                            </Link>
                          </li>
                          <li>
                            <Link to="https://bydautoindia.com/about_byd?#pollutioncontrol">
                              Pollution Control
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li>
                      <span onClick={() => setCorparate(!corparate)}>
                        Corporate Presence
                      </span>
                      {corparate && (
                        <ul className="navbar_bottom_mobile_ul_ul">
                          <li>
                            <Link to="https://bydautoindia.com/about_byd?#csr">
                              CSR
                            </Link>
                          </li>
                          <li>
                            <Link to="https://bydautoindia.com/about_byd?#ar">
                              Corporate Governance
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <Link to="https://bydautoindia.com/news">
                  {" "}
                  <span>News</span>
                </Link>
              </li>
              <li>
                <Link to="https://bydautoindia.com/events">
                  <span>Events</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
