import "./ContactUs.css";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  Modal,
  Typography,
} from "@mui/material";
function ContactUs() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "transprent",
    border: "0px solid transprent",
    boxShadow: 0,
    p: 4,
    outline: 0,
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 4,
    outline: 0,
    borderRadius: "5px",
  };
  const form = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // console.log(data)
    sendEmail(data);
  };
  const sendEmail = (data) => {
    // e.preventDefault();
    // console.log(data,'sendEmail');
    setIsLoading(true);
    emailjs
      .sendForm(
        "service_z13mylw",
        "template_ajbc87v",
        form.current,
        "wRC3uTMjh6IWirt4a"
      )
      .then(
        (result) => {
          setIsLoading(false);
          if (result.status === 200) {
            handleOpen();
            reset({
              name: "",
              email: "",
              contact: "",
              selectedValue: "",
              selectedMonth: "",
            });
          }
          // console.log(hello);
        },
        (error) => {
          // console.log(error.text);
          setIsLoading(false);
          alert("SomeThing Went Wrong,Please Try Again");
        }
      );
  };
  return (
    <div className="contactus">
      <div className="contactus_left"></div>
      <div className="contactus_right">
        <form onSubmit={handleSubmit(onSubmit)} ref={form}>
          <p>Interested to know more about BYD vehicles</p>
          <p>Please share your contact details and we will connect with you.</p>
          <span>
            <input
              type="text"
              placeholder="Name"
              {...register("name", {
                required: true,
                validate: (value) => {
                  return !!value.trim();
                },
              })}
            />
          </span>
          {errors.name && <p className="loginFormError">Please enter Name</p>}
          <span>
            <input
              type="text"
              placeholder="Contact-No."
              {...register("contact", {
                required: true,
                maxLength: 13,
                pattern: {
                  value: /^(0|[1-9]\d*)(\.\d+)?$/,
                },
              })}
            />
          </span>
          {errors.name && (
            <p className="loginFormError">Please enter valid Contact No.</p>
          )}
          <span>
            <input
              type="text"
              placeholder="Email"
              {...register("email", {
                required: true,
                pattern:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
          </span>
          {errors.name && (
            <p className="loginFormError">Please enter valid Email</p>
          )}
          <div className="contactus_right_div">
            {/* <span onClick={() => setActive(!active)}>
              <p>{selectedValue}</p>
              <KeyboardArrowDown style={{ color: "#ccc" }} />
            </span>
            {active && (
              <span className="contactus_right_selected_value">
                <p onClick={getValuehandle}>BYD ATTO 3</p>
                <p onClick={getValuehandle}>All-New e6</p>

                <p onClick={getValuehandle}>BYD SEAL</p>
              </span>
            )} */}
            <span>
              <select
                {...register("selectedValue", { required: true })}
                className="contactus_right_select"
              >
                <option value="" className="contactus_right_select_option">
                  Model
                </option>
                <option
                  value="BYD ATTO 3"
                  className="contactus_right_select_option"
                >
                  BYD ATTO 3
                </option>
                <option
                  value="All-New e6"
                  className="contactus_right_select_option"
                >
                  All-New e6
                </option>
                <option
                  value="BYD SEAL"
                  className="contactus_right_select_option"
                >
                  BYD SEAL
                </option>
              </select>
            </span>

            {errors.selectedValue && (
              <p className="loginFormError" style={{ width: "100%" }}>
                Please select Model
              </p>
            )}
            <span>
              <select
                {...register("selectedMonth", { required: true })}
                className="contactus_right_select"
              >
                <option value="" className="contactus_right_select_option">
                  Select Month
                </option>{" "}
                <option
                  value="Less than a Month"
                  className="contactus_right_select_option"
                >
                  Less than a Month
                </option>{" "}
                <option
                  value="With In Three Months"
                  className="contactus_right_select_option"
                >
                  With In Three Months
                </option>{" "}
                <option
                  value="More Than Three Months"
                  className="contactus_right_select_option"
                >
                  More Than Three Months
                </option>
              </select>
            </span>
            {errors.selectedMonth && (
              <p className="loginFormError" style={{ width: "100%" }}>
                This Field is Required
              </p>
            )}
          </div>

          <div>
            <button>Send</button>
          </div>
        </form>
      </div>
      {isLoading && (
        <Box sx={style}>
          <CircularProgress />
        </Box>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style1}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Thanks For Showing Interest
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Our Team Will Reach You Soon!
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default ContactUs;
