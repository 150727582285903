import React from "react";
import "./Homepage.css";
import HomepageBanner from "../../layout/HomepageBanner/HomepageBanner";
import HomeBottom from "../../layout/HomeBottom/HomeBottom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "swiper/css/autoplay";
import { Navigation, EffectFade } from "swiper";
// import Swiper styles
import "swiper/css/effect-fade";
import "swiper/css";
import img1 from "../../asset/21-1.webp";
import img2 from "../../asset/21-2.webp";
import img3 from "../../asset/21-3.jpg";
import img4 from "../../asset/21-4.webp";
import img5 from "../../asset/21-5.webp";
import img11 from "../../asset/21-1-1.jpeg";
import img22 from "../../asset/21-2-2.webp";
import img33 from "../../asset/21-3-3.jpg";
import img44 from "../../asset/21-4-4.webp";
import img55 from "../../asset/21-5-5.jpg";

function Homepage() {
  const data = [
    {
      image: img1,
      sImage:img11,
      link: "https://bydautoindia.com/bydatto3",
    },
    {
      image: img2,
      sImage:img22,
      link: "https://bydautoindia.com/bydatto3",
    },
    {
      image: img3,
      sImage:img33,
      link: "https://bydautoindia.com/all-new-e6",
      title:'All-New e6',
      subtitle:`India's First Premium Electric MPV`,
    },
    {
      image: img4,
      sImage:img44,
      link: "https://bydautoindia.com/bydatto3",
    },
    {
      image: img5,
      sImage:img55,
      link: "https://bydautoindia.com/bydatto3",
    },
  
  ];
  return (
    <div className="homepage">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        effect={"fade"}
        modules={[Navigation, EffectFade]}
        navigation
        loop
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
      >
        {data.map((item) => (
          <SwiperSlide>
            <HomepageBanner sImage={item?.sImage && item?.sImage} image={item?.image} link={item?.link} title={item?.title && item?.title} subtitle={item?.subtitle && item?.subtitle} />
          </SwiperSlide>
        ))}
      </Swiper>
      <HomeBottom />
    </div>
  );
}

export default Homepage;
